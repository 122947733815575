import { ContextValue } from 'store/types'

//============ INTERNAL ===============
import useStore from 'store/useStore'

function useCustomer() {
  const [state = {}]: ContextValue = useStore()

  const { customer } = state.customers || {}

  return {
    customer,
  }
}

export default useCustomer