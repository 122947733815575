import React, { useReducer } from 'react';
import StoreContext from './StoreContext';

import { StoreType } from './types'


function Store({
  rootReducer,
  initialValue,
  children,
}: StoreType) {
  const initialState = rootReducer(initialValue, { type: '@INIT' });
  const [state, dispatch] = useReducer(rootReducer, initialState);

  return <StoreContext.Provider value={[state, dispatch]}>{children}</StoreContext.Provider>;
}

export { StoreContext };

export default Store;