import axios from 'axios';

 /**
    * Generic handler for a base implementation
    *
    * @param { string } baseURL: The base url for the request
  */
const api = (baseURL: string) => axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    common: {
      'Accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
    }
  }
})

export default api