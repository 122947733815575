import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history";
import  config from "../../../../config/settings";
import "./error.scss";

// Your component own properties
type PropsType = RouteComponentProps & {
  error: string,
  history: History<LocationState>
}

const Error: React.FC<PropsType> = ({ error, history }) => (
  <div className="errorWrapper">
    {error}
    <div onClick={() => history.push('/verify')}>
      <button className="btn btn-error">Go Back</button>
    </div>
    <br />
    Kindly try again or contact support via phone call and WhatsApp on {config.supportNumber}
  </div>
)

export default withRouter(Error)