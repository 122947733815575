import initialState from './initialState';
import actionTypes from '../actions/actionTypes';
import { Action } from 'store/types'

type State = typeof initialState.aggregator

const aggregatorReducer = (
  state: State = initialState.aggregator,
  action: Action 
) => {
  switch(action.type) {
    case actionTypes.AGGREGATOR_SIGN_IN_START:
      return { ...state, error: false, loading: true }

    case actionTypes.AGGREGATOR_SIGN_IN_COMPLETE:
      return {
        ...state,
        error: false,
        loading: false,
        ...((action.payload && action.payload.data) || {}),
      }

    case actionTypes.AGGREGATOR_SIGN_IN_FAIL:
      return { 
        loading: false,
        error: action.payload
      }

    case actionTypes.AGGREGATOR_SIGN_UP_START:
      return { ...state, error: false, loading: true }

    case actionTypes.AGGREGATOR_SIGN_UP_COMPLETE:
      return {
        ...state,
        error: false,
        loading: false,
        ...((action.payload && action.payload.data) || {}),
      }

    case actionTypes.AGGREGATOR_SIGN_UP_FAIL:
      return { 
        loading: false,
        error: action.payload
      }

    default:
      return state;
  }
}

export default aggregatorReducer