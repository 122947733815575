import * as React from 'react'

interface Props {
  className: string,
}
const Footer: React.FC<Props> = () => {
  return (
    <div className="footer">
      (c) AjoCard ({(new Date()).getFullYear()}). All Rights Reserved.
    </div>
  )
}

export default Footer 