import actionTypes from './actionTypes';
import { Dispatch } from 'react'
import api from 'services/api'
import { Action } from '../../../store/types'
import settings from 'config/settings'

type paramsType = {
  phoneNumber: string,
  cardSerialNo: string,
}

export function verifyCustomer(dispatch: Dispatch<Action>, params: paramsType): Promise<void> {
  // call to verify customer
  dispatch({ type: actionTypes.CUSTOMER_SHOW_START })
  return api(settings.insuranceBaseUrl).get("/v1/public/verify", { params }).then((res): void => dispatch({
    type: actionTypes.CUSTOMER_SHOW_COMPLETE,
    payload: { data: { ...(res.data && res.data.response), ...params } }
  })).catch(e => {
      return dispatch({
        type: actionTypes.CUSTOMER_SHOW_FAIL,
        payload: `${e.message}. An error occured while retrieving customer details for member number ${params.phoneNumber} and card serial no: ${params.cardSerialNo}.`,
      })
    }
  )
}

export default {
  verifyCustomer
}