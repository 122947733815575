import React from "react";
import { NavLink } from "react-router-dom";
import "./nav.scss";

interface Props {
  className?: string,
}

const Nav: React.FC<Props> = () => {
  return (
    <div className="nav-scroller bg-warning shadow-sm mb-4">
      <nav className="nav nav-underline container">
        <NavLink exact className="nav-link pt-2 pb-3 mt-1" to="/verify">
          <i className="fa fa-home"/> Home
        </NavLink>
        {/* <NavLink className="nav-link pt-2 pb-3 mt-1" to="/aggregator">
          <i className="fa fa-group" /> Aggregator
        </NavLink> */}
      </nav>
    </div>
  )
}

export default Nav;