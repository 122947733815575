import combineReducers from './combineReducers';
import customers from 'components/customers/reducer/index'
import aggregators from 'components/aggregators/reducer/index'
import { Reducer } from 'store/types'

const rootReducer: Reducer = combineReducers({
  customers,
  aggregators,
})

export default rootReducer;