import * as React from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/images/ajocard-logo.png'
import './header.scss'
import Nav from 'components/Nav'

export interface Props {
  className: string,
}


const Header: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <div className="up">
        <div className="pull-left">
          <Link to="/">
            <img alt="AjoCard" src={logo} />
          </Link>
        </div>
        <div className="pull-right d-none">
          <a href="https://ajocard.com">
            Home
          </a>
        </div>
      </div>
      <Nav className="" />
    </div>
  )
}

export default Header