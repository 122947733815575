import * as React from 'react'
import { withRouter } from 'react-router-dom'
import useVerificationForm from './useVerificationForm'
import { History, LocationState } from "history";
import './verification-form.scss'
import cardImage from 'assets/images/card-image.png'

interface props {
  history: History<LocationState>
}

const validateChars = (
  event: React.KeyboardEvent<HTMLDivElement>,
  maxLength: number,
) => (
  (isNaN(Number(String.fromCharCode(event.which))) ||
    (event.currentTarget.innerText.length > maxLength))
  && event.preventDefault()
)

const VerificationForm: React.FC<props> = ({
  history
}) => {
  const {
    error,
    phoneNumber,
    setPhoneNumber,
    firstHalfSerial,
    setFirstHalfSerial,
    lastHalfSerial,
    setLastHalfSerial,
    onSubmit,
    customer,
  } = useVerificationForm({ history })

  if (error) return <div>An error occured</div>
  if (customer?.loading) return <div>Loading ...</div>

  return (
    <div>
      <div className="wrapper">
        <div className="card-verification container">
          <div className="row">
            <div className="col-md-5 d-flex justify-content-center align-items-center">
              <form className="card-verification-form">
                <div className="form-group">
                  <label>Member Number</label>
                  <input type="text" className="input-field"
                          onChange={
                            e => !Object.is(Number(e.currentTarget.value), NaN) && 
                            setPhoneNumber(e.currentTarget.value)
                          } 
                          value={phoneNumber} />
                </div>
                <div className="form-group">
                  <label>Card Number</label>
                  <div className="input-field card-holder">
                    <div className="label">
                      AJOC-MIND-
                    </div>
                    <div className="input" 
                          contentEditable
                          onKeyPress={(e) => validateChars(e, 3) }
                          onKeyUp={(e) => setFirstHalfSerial(e.currentTarget.innerText) }
                          placeholder="XXXX">
                    </div>
                    <div className="input" 
                          contentEditable
                          onKeyPress={(e) => validateChars(e, 9) }
                          onKeyUp={(e) => setLastHalfSerial(e.currentTarget.innerText) }
                          placeholder="XXXXXXXXXX">
                    </div>
                  </div>
                </div>
                <div className="form-group justify-content-center align-items-center">
                  <button className="btn mx-auto submit btn-warning w-50 text-center"
                          disabled={(firstHalfSerial.length < 4) || (lastHalfSerial.length < 4)}
                          onClick={(e) => onSubmit(e)}>
                    Search
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-6 p-3 d-flex justify-content-center align-items-center">
              <img src={`${cardImage}`} alt="card-img" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(VerificationForm);