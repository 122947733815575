import { verifyCustomer } from '../actions/actions'
import { useState } from 'react'
import { History, LocationState } from "history";

//============ INTERNAL ===============
import useStore from 'store/useStore'
import { ContextValue } from 'store/types'

const CARD_PREFIX = 'AJOC-MIND'

interface props {
  history: History<LocationState>
}

function useVerificationForm({ history }: props) {
  const [state = {}, dispatch]: ContextValue = useStore()
  const [error, setError] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [firstHalfSerial, setFirstHalfSerial] = useState('')
  const [lastHalfSerial, setLastHalfSerial] = useState('')

  const { customer } = state.customers || {}

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const serial = `${CARD_PREFIX}-${firstHalfSerial}-${lastHalfSerial}`
    const customerData = { phoneNumber, cardSerialNo: serial }
    verifyCustomer(dispatch, customerData)
    .then(e => history.push(`/verify/customer/${serial}`))
      .catch((err: any) => setError(err))

  }

  return {
    customer,
    error,
    phoneNumber,
    setPhoneNumber,
    firstHalfSerial,
    setFirstHalfSerial,
    lastHalfSerial,
    setLastHalfSerial,
    onSubmit,
  }
}

export default useVerificationForm