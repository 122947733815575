import * as React from 'react'
import { withRouter } from 'react-router-dom'

//====== INTERNAL ======
import useCustomer from './useCustomer'
import Error from './error'
import {
  PropsType,
  customerType,
} from './types'
import './customer.scss';

const Customer: React.FC<PropsType> = ({
  history
}) => {
  const { customer = {} }: { customer: customerType} = useCustomer()

  if (customer.error) return <Error error={customer.error} />

  if (customer.loading) return <div>Loading...</div>

  if (!customer.address) history.push('/verify')

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <img
            alt="user-img"
            src={customer.passportLink && !customer.passportLink.includes("drive") ? customer.passportLink : "https://via.placeholder.com/900?text=image+is+not+yet+available"}
            className="img-fluid rounded-circle"
          />
        </div>
        <div className="col-md-9 row customer-details">
          <div className="col-12 col-sm-5 col-md-5 col-lg-6">
            <table>
              <tbody>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td>FIRST NAME</td>
                  <td>{customer.firstName}</td>
                </tr>
                <tr>
                  <td>LAST NAME</td>
                  <td>{customer.lastName}</td>
                </tr>
                <tr>
                  <td>CARD NUMBER</td>
                  <td>{customer.cardSerialNo}</td>
                </tr>
                <tr>
                  <td>PHONE NUMBER</td>
                  <td>{customer.phoneNumber}</td>
                </tr>
                <tr>
                  <td>DATE OF BIRTH</td>
                  <td>{customer.dob}</td>
                </tr>
                <tr>
                  <td>GENDER</td>
                  <td>{customer.gender}</td>
                </tr>
                <tr>
                  <td>STATE</td>
                  <td>{customer?.address?.state}</td>
                </tr>
                <tr>
                  <td>LGA</td>
                  <td>{customer?.address?.lga}</td>
                </tr>
                <tr>
                  <td>CITY</td>
                  <td>{customer?.address?.city}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 col-sm-5 col-md-5 col-lg-5">
            <table>
              <tbody>
                <tr>
                  <td><strong>HEALTH INSURANCE</strong></td>
                </tr>
                <tr>
                  <td>Provider</td>
                  <td>{customer?.hmoInfo?.hmoName}</td>
                </tr>
                <tr>
                  <td>POLICY NUMBER</td>
                  <td>{customer?.hmoInfo?.policyNumber}</td>
                </tr>
                <tr>
                  <td>INSURANCE PLAN</td>
                  <td>{customer?.hmoInfo?.plan}</td>
                </tr>
                <tr>
                  <td>Passport Link</td>
                  <td>{customer?.passportLink ? <a href={customer?.passportLink}>Link to Passport</a> : "None Present"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <button className="btn" onClick={() => history.push('/verify')}>
          Go back
        </button>
      </div>
    </div>
  )
}

export default withRouter(Customer);