import { State, Action } from 'store/types'
import initialState from './initialState'

const combineReducers = (reducers: any) => (
  state: State = initialState,
  action?: Action,
): State => {
  const reducerNames: Array<string> = Object.keys(reducers);
  const nextState: State = {};
  let hasChanged: boolean = false;
  reducerNames.forEach((reducerName: string) => {
    const previousStateForKey: Object = state[reducerName];
    const nextStateForKey = reducers[reducerName](previousStateForKey, action);
    if (typeof nextStateForKey === 'undefined') {
      throw new Error(`${reducerName} Reducer returns undefined!`);
    }
    hasChanged = hasChanged || nextStateForKey !== previousStateForKey;
    nextState[reducerName] = nextStateForKey;
  });
  // making sure we're only returning a new object when there's been a change
  return hasChanged ? nextState : state;
};

export default combineReducers;