import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import Header from '../header';
import CustomerVerificationPage from '../customers/verification-form';
import CustomerPage from '../customers/customer';
import Footer from '../footer';
// import Aggregator from 'components/aggregators';
// import AuthForm from 'components/aggregators/auth-form';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header className="app-header" />
      <div className="app-body">
        <Switch>
          <Route exact path="/verify" component={CustomerVerificationPage} />
          {/* <Route exact path="/aggregator" component={AuthForm} /> */}
          {/* <Route exact path="/aggregator/me" component={Aggregator} /> */}
          <Route path="/verify/customer/:id" component={CustomerPage} />
          <Route path="*" render={() => <Redirect to="/verify" />} />
        </Switch>
      </div>
      <Footer className="app-footer" />
    </div>
  );
}

export default App;
